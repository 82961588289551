import React, { FC, useEffect, useState } from 'react';
import StyledBrowseInput from 'shared/components/atoms/BrowseInput';
import Button, { ButtonMainStyle } from 'shared/components/atoms/Button';
import SectionHeader from 'shared/components/atoms/SectionHeader';
import HeaderWithChildrenWrapper from 'shared/components/molecules/HeaderWithChildrenWrapper';
import { pages, useTrackPage } from 'shared/utils/trackPage';
import styled from 'styled-components';
import { EditableField } from '../EditableField';
import DatePicker from 'react-datepicker';
import { formatDate } from 'shared/utils/dateHelper';
import { HtmlEditor } from 'shared/components/organisms/HtmlEditor';
import { usePromotions } from 'PromoOffer/promoApi';
import { toUpdatePromoBody, UpdatePromotionBody, UpdatePromotionRequest } from 'PromoOffer/crud/updatePromoOffer';
import { IPromoOffer } from 'PromoOffer/promoTypes';
import ToggleButton from 'shared/components/molecules/ToggleButton';
import { PromoBanner } from 'Welcome/PromoBanner';
import Column from 'shared/components/atoms/Column';
import Dropdown, { IDropdownOption } from '../../../shared/components/molecules/Dropdown';
import { selectHasEditPromoOffersPermissions } from 'User/selectors';
import { useSelector } from 'react-redux';
import { forbidden } from 'Auth/routes';
import { useNavigate } from 'react-router-dom';

interface IPromoOfferProps {
  className?: string;
}
const UnstyledPromoOfferSettings: FC<React.PropsWithChildren<IPromoOfferProps>> = ({ className }) => {
  const { promotions, isLoading, updatePromotion } = usePromotions();

  return (
    <PromoOfferSettingsInternal
      className={className}
      contentList={promotions}
      isLoading={isLoading}
      updatePromotion={updatePromotion}
    />
  );
};

function CurrentImage({ url, isDisplayed }: { url: string | undefined; isDisplayed: boolean }) {
  return (
    <div>
      {!isDisplayed && (
        <h4>
          <em>Image currently hidden</em>
        </h4>
      )}
      <img src={url} />
    </div>
  );
}

export const PromoOfferSettingsInternal: FC<
  IPromoOfferProps & {
    contentList: IPromoOffer[] | undefined;
    updatePromotion: (data: { promoId: string; data: UpdatePromotionBody }) => void;
    isLoading: boolean;
  }
> = ({ className, contentList, isLoading, updatePromotion }) => {
  useTrackPage(pages.promoOffer);

  const navigate = useNavigate();
  const hasPermission = useSelector(selectHasEditPromoOffersPermissions);

  useEffect(() => {
    if (!hasPermission) {
      navigate(forbidden);
    }
  }, [hasPermission, navigate]);

  if (!hasPermission) {
    return null;
  }

  const contentOptions: IDropdownOption[] = [
    { label: "Dashboard promotion", value: "1234567890" },
    { label: "Home introduction", value: "1" },
    { label: "Orders and recent quotes", value: "2" },
    { label: "Information box", value: "3" },
    { label: "Pricing updates", value: "4" },
    { label: "Contact information", value: "5" },
  ];

  const [isEditing, setIsEditing] = useState(false);
  const [selectedContent, setSelectedContent] = useState<IPromoOffer>();
  const [selectedContentId, setSelectedContentId] = useState("1234567890");  
  
  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');
  const [image, setImage] = useState<File | null>(null);
  const [imagePreviewURL, setImagePreviewURL] = useState('');
  const [displayImage, setDisplayImage] = useState(false);
  const [validFrom, setValidFrom] = useState<Date | null>();
  const [validUntil, setValidUntil] = useState<Date | null>();

  const onSelectedContentChange = (item: IDropdownOption) => {
    setSelectedContent(contentList?.find(c => c.id === item.value));
    setSelectedContentId(item.value);
  };

  useEffect(() => {    
    if(!selectedContent){
      setSelectedContent(contentList?.find(c => c.id === selectedContentId));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentList]);


  useEffect(() => {
    if (!image) return;

    const objectUrl = URL.createObjectURL(image);
    setImagePreviewURL(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [image]);

  const setFieldsToCurrentContent = (content: IPromoOffer | undefined) => {
    setTitle(content && content.attributes.title || '');
    setBody(content && content.attributes.body || '');
    setValidFrom(content && new Date(content.attributes.validFrom!) || new Date());
    setValidUntil(content && new Date(content.attributes.validUntil!) || new Date());
    setDisplayImage(content && content.attributes.banner.display || false);
  };
  useEffect(() => {
    setFieldsToCurrentContent(selectedContent);
  }, [selectedContent]);

  const resetChanges = () => {
    setIsEditing(false);
    setFieldsToCurrentContent(selectedContent!);
  };

  const renderEditingButtons = () => {
    return (
      <div className="editing-buttons">
        <Dropdown           
          placeholder="Search for Content"
          defaultValue={contentOptions[0]}
          onChange={onSelectedContentChange}
          options={contentOptions}
          isClearable={false}
          className="dropdown"
        />
        {isEditing && (
          <div className="d-flex">
            <Button mainStyle={ButtonMainStyle.PrimaryOutline} disabled={false} onClick={resetChanges}>
              Cancel
            </Button>

            <Button mainStyle={ButtonMainStyle.Primary} disabled={false} onClick={onSubmit}>
              Save changes
            </Button>
          </div>
        )}

        {!isEditing && (
          <Button
            mainStyle={ButtonMainStyle.PrimaryOutline}
            disabled={false}
            onClick={() => {
              setIsEditing(true);
            }}
          >
            Edit
          </Button>
        )}
      </div>
    );
  };

  const onSubmit = async () => {
    setIsEditing(false);

    const data: Partial<UpdatePromotionRequest> = {
      title: title,
      body: body,
      valid_from: validFrom ? validFrom.toISOString() : null,
      valid_until: validUntil ? validUntil.toISOString() : null,
      banner_hidden: !displayImage,
    };

    updatePromotion({
      promoId: selectedContentId,
      data: await toUpdatePromoBody(data, image),
    });

    contentList = undefined;
    setSelectedContent(undefined);
  };

  const [preview, setPreview] = useState<any>();
  useEffect(() => {
    if (selectedContent){
      setPreview(
        <Column lgWidth={8}>
          <PromoBanner {...selectedContent.attributes} forceDisplay={true} isPromo={selectedContent?.id === "1234567890"} />
        </Column>);
    } else {
      setPreview(
        <Column lgWidth={8}>
          [<b>No content to preview</b>]
        </Column>);
    }
  }, [selectedContent]);

  return (
    <HeaderWithChildrenWrapper className="" header="Content Manager" isLoading={isLoading}>
      <div className={className}>
        <SectionHeader rightSideContent={renderEditingButtons()} text="Select content to edit:" />

        {!isEditing && (
          <div className="mb-3" data-testid="promo-preview">
            <h2>Content Preview</h2>
            {preview}
          </div>
        )}

        <br />
        <div className="promo-form" data-testid="promo-form">
          <h2>Current Content</h2>
          <EditableField label="Title" isEditing={isEditing} value={selectedContent?.attributes.title}>
            <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} maxLength={50} />
          </EditableField>

          <EditableField
            label="Body"
            isEditing={isEditing}
            customDisplayValue={
              <div dangerouslySetInnerHTML={{
                  __html: selectedContent?.attributes.body || '',
                }}
              ></div>
            }
          >
            <HtmlEditor value={body} onChange={setBody} />
          </EditableField>

          
          { selectedContent?.id === "1234567890" && 
          <EditableField
            label="Image"
            isEditing={isEditing}
            customDisplayValue={
              <CurrentImage
                url={selectedContent?.attributes.banner.url}
                isDisplayed={selectedContent?.attributes.banner.display ?? false}
              />
            }
          >
            <ToggleButton
              defaultChecked={displayImage}
              label="Display image alongside promotion"
              onToggleChange={(event) => setDisplayImage(event.target.checked)}
            />
            <div className="mb-3">
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h4>New Image</h4>
                <StyledBrowseInput
                  onUploadFile={(files) => {
                    setImage(files[0]);
                  }}
                  inputText="Upload image"
                  accept="image/*"
                />
              </div>
              {imagePreviewURL && <img className="mt-3" src={imagePreviewURL} />}
            </div>

            <div className="mb-2">
              <h4>Current Image</h4>
              {selectedContent?.attributes.banner.url && <img src={selectedContent?.attributes.banner.url} />}
            </div>
          </EditableField>}

          { selectedContent?.id === "1234567890" && 
          <EditableField
            label="Valid from"
            isEditing={isEditing}
            value={selectedContent?.attributes.validFrom ? formatDate(selectedContent?.attributes.validFrom) : ''}
          >
            <DatePicker
              popperPlacement="bottom"
              isClearable
              onChange={(date) => {
                setValidFrom(date as Date);
              }}
              selected={validFrom}
              dateFormat="dd/MM/yyyy"
            />
          </EditableField>}

          { selectedContent?.id === "1234567890" && 
          <EditableField
            label="Valid until"
            isEditing={isEditing}
            value={selectedContent?.attributes.validUntil ? formatDate(selectedContent?.attributes.validUntil) : ''}
          >
            <DatePicker
              popperPlacement="bottom"
              isClearable
              onChange={(date) => {
                setValidUntil(date as Date);
              }}
              selected={validUntil}
              dateFormat="dd/MM/yyyy"
            />
          </EditableField>}
        </div>
      </div>
    </HeaderWithChildrenWrapper>
  );
};

const PromoOfferSettings = styled(UnstyledPromoOfferSettings)`
  .promo-form {
    display: flex;
    flex-direction: column;
    width: 50%;
    row-gap: 2em;
  }

  .editing-buttons {
    width: 75%;
    display: flex;
    justify-content: space-between;
    .dropdown {
      width: 350px;
    }
    button {
      width: 170px;
      height: 44px;
      float: right;
    }
  }
  .editing-field {
    .ql-editor{
      min-height: 150px;
    }
    .ql-container{
      font-size: inherit;
      font-family: inherit;
    }
  }

  img {
    max-width: 100%;
  }

  input {
    width: 100%;
  }
`;

export default PromoOfferSettings;
